<template>
  <v-main id="home">
    <router-view />
  </v-main>
</template>

<script>
  export default {
    name: 'CoreView',
  }
</script>
